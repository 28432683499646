import Vue from 'vue';

// we import only the icons we actually use in the project to dramatically reduce bundle size
import 'vue-awesome/icons/sign-out-alt';
import 'vue-awesome/icons/sign-in-alt';
import 'vue-awesome/icons/sort';
import 'vue-awesome/icons/sort-up';
import 'vue-awesome/icons/sort-down';
import 'vue-awesome/icons/star';
import 'vue-awesome/icons/regular/star';
import 'vue-awesome/icons/spinner';
import 'vue-awesome/icons/external-link-alt';
import 'vue-awesome/icons/exclamation-triangle';
import 'vue-awesome/icons/caret-up';
import 'vue-awesome/icons/chevron-up';
import 'vue-awesome/icons/chevron-right';
import 'vue-awesome/icons/chevron-down';
import 'vue-awesome/icons/tools';
import 'vue-awesome/icons/eye';
import 'vue-awesome/icons/check';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/plus';
import 'vue-awesome/icons/plus-square';
import 'vue-awesome/icons/minus';
import 'vue-awesome/icons/newspaper';
import 'vue-awesome/icons/key';
import 'vue-awesome/icons/calendar';
import 'vue-awesome/icons/user';
import 'vue-awesome/icons/trash';
import 'vue-awesome/icons/tasks';
import 'vue-awesome/icons/pen-alt';
import 'vue-awesome/icons/history';
import 'vue-awesome/icons/comment';
import 'vue-awesome/icons/search';
import 'vue-awesome/icons/question-circle';

import Icon from 'vue-awesome/components/Icon';

Vue.component('icon', Icon);

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"col-lg-3 col-sm-12 text-center version-info"},[_c('div',{staticClass:"align-bottom"},[_c('a',{attrs:{"href":_vm.serverURL,"target":"_blank"}},[_vm._v("SVIPdb")]),_vm._v(" "+_vm._s(_vm.appVersion)+" ("+_vm._s(_vm.releaseName)+") "),_c('div',{staticClass:"feedback"},[_c('anchor-router-link',{attrs:{"to":{name: 'about', hash: '#disclaimer'},"scrollOptions":{
                                container: 'body',
                                duration: 700,
                                easing: 'ease',
                            }}},[_vm._v(" Disclaimer & License ")])],1),_vm._m(0)])]),(_vm.windowWidth > 1000)?_c('div',{staticClass:"col-lg-9 col-sm-12"},[_vm._m(1)]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feedback"},[_vm._v(" Questions or Comments: "),_c('br'),_c('a',{attrs:{"href":"mailto:feedback@svip.ch"}},[_vm._v("feedback@svip.ch")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid attributions"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-4 col-sm-12 text-center"},[_c('a',{attrs:{"href":"https://www.ethz.ch","target":"_blank"}},[_c('img',{staticClass:"footer_logo",attrs:{"src":require("../../assets/logos/eth_logo_small.png"),"alt":"ETH"}})])]),_c('div',{staticClass:"col-lg-4 col-sm-12 text-center"},[_c('a',{attrs:{"href":"https://www.sib.swiss/","target":"_blank"}},[_c('img',{staticClass:"footer_logo",attrs:{"src":require("../../assets/logos/sib_logo_medium_titled.png"),"alt":"SIB"}})])]),_c('div',{staticClass:"col-lg-4 col-sm-12 text-center"},[_c('a',{attrs:{"href":"https://www.hes-so.ch/","target":"_blank"}},[_c('img',{staticClass:"footer_logo",attrs:{"src":require("../../assets/logos/logo-hes-so-noir-433.png"),"alt":"HES-SO"}})])])])])
}]

export { render, staticRenderFns }
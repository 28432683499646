var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar',{staticClass:"svip-navbar",attrs:{"toggleable":"md","type":"dark","variant":"primary","fixed":"top"}},[_c('b-navbar-toggle',{attrs:{"target":"nav_collapse"}}),_c('b-navbar-brand',[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../assets/logos/SVIP_Logo_white_narrow.png"),"width":"162","height":"52","alt":"SVIP-O"}})])],1),_c('b-collapse',{attrs:{"is-nav":"","id":"nav_collapse"}},[_c('b-navbar-nav',[(
                    _vm.user &&
                    (_vm.user.groups.indexOf('curators') != -1 || _vm.user.groups.indexOf('clinicians') != -1)
                )?_c('b-nav-item',{attrs:{"to":{name: 'curation-dashboard'}}},[_vm._v(" Dashboard ")]):_vm._e(),(_vm.user && _vm.user.groups.indexOf('submitters') != -1)?_c('b-nav-item',{attrs:{"to":{name: 'submit-variants'}}},[_vm._v(" Submit Variants ")]):_vm._e(),_c('b-nav-item',{attrs:{"to":'/help'}},[_vm._v("Help")]),_c('b-nav-item',{attrs:{"to":'/about'}},[_vm._v("About")]),_c('b-nav-item',{attrs:{"to":'/statistics'}},[_vm._v("Statistics")]),_c('b-nav-item',{attrs:{"to":'/releases'}},[_vm._v("Releases")])],1),(!_vm.loginDisabled)?_c('b-navbar-nav',{staticClass:"ml-auto"},[(_vm.user)?_c('b-navbar-nav',{attrs:{"right":""}},[_c('b-nav-text',{staticClass:"login-name"},[_vm._v(" logged in as "),_c('router-link',{attrs:{"to":"/user-info"}},[_vm._v(_vm._s(_vm.user.username))]),_vm._v(" - ")],1),_c('b-nav-item',[_c('a',{staticClass:"pointer",on:{"click":function($event){return _vm.logout()}}},[_c('icon',{attrs:{"name":"sign-out-alt"}}),_vm._v(" log out ")],1)])],1):_c('b-navbar-nav',{attrs:{"right":""}},[(_vm.$router.currentRoute !== '/login')?_c('b-nav-item',[_c('router-link',{staticClass:"pointer",attrs:{"to":{
                            name: 'login',
                            params: {nextRoute: _vm.whereFromHere},
                        }}},[_c('icon',{attrs:{"name":"sign-in-alt"}}),_vm._v(" log in ")],1)],1):_vm._e()],1)],1):_vm._e()],1),_c('div',{staticClass:"ajax-loader-bar",staticStyle:{"position":"relative","z-index":"1035"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }